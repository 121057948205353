import React, { useEffect } from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { Hero } from '../../components/enfusion/Hero';
import { useAppContext } from '../../util/context';

function HeroSlider({ meta, data, className, anchor, pageProps }) {
    const context = useAppContext();

    const { themeAssets } = pageProps;
    
    let slides = extractRepeaterField(data, 'slide');

    slides = slides.map((repeaterItem) => ({
        ...repeaterItem,
        background_desktop: meta?.images[repeaterItem.background_desktop] || {},
        background_mobile: meta?.images[repeaterItem.background_mobile] || {},
        main_image: meta?.images[repeaterItem.main_image] || {},
        background_pattern: themeAssets[repeaterItem.background_pattern] || {},
        gradient_overlay: themeAssets[repeaterItem.gradient_overlay] || {},
        heading_prefix: themeAssets[repeaterItem.heading_prefix] || {}
    }));

    return <Hero className={className} anchor={anchor} slides={slides} />;
}

export default HeroSlider;
