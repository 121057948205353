import { useState, useEffect } from "react";
import Image from "next/image";

export default function ImageSlider({ images, initialIndex = 0, onImageClick, autoplay = true, isPopup = false, content }) {
  const [currentIndex, setCurrentIndex] = useState(content ? 0 : initialIndex);
  const [isHovered, setIsHovered] = useState(false);

  // Total number of slides including content (if it exists)
  const totalSlides = content ? images.length + 1 : images.length;

  // Adjust index for image slide if content exists
  const adjustedIndex = content ? currentIndex - 1 : currentIndex;

  // Previous slide logic
  const prevSlide = () => {
    console.log('prevSlide',currentIndex)
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalSlides) % totalSlides);
    // setCurrentIndex((prevIndex) => (prevIndex === 0 ? totalSlides - 1 : prevIndex - 1));

  };

  // Next slide logic
  const nextSlide = () => {
    console.log('nextSlide',currentIndex)
    // setCurrentIndex((prevIndex) => (prevIndex === totalSlides - 1 ? 0 : prevIndex + 1));
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalSlides);
  };

  // Autoplay functionality
  useEffect(() => {
    if (autoplay && !isHovered) {
      const interval = setInterval(nextSlide, 3000);
      return () => clearInterval(interval);
    }
  }, [isHovered, autoplay]);

  // Mouse event handlers to pause/resume autoplay
  const handleMouseOver = () => {
    if (autoplay) setIsHovered(true);
  };

  const handleMouseLeave = () => {
    if (autoplay) setIsHovered(false);
  };

  return (
    <div
      className={`relative ${isPopup ? 'md:w-[605px] md:h-[473px] w-[300px] h-[300px]' : 'md:w-[352px] w-[320px] h-[280px] career-carousel-item'}`}
      style={{ borderRadius: '16px', overflow: 'hidden' }} // Adding border-radius and ensuring overflow is hidden
    >
      {/* Display content if it's the first slide (currentIndex === 0) */}
      {currentIndex === 0 && content ? (
        <div
          className="flex items-center justify-center w-full h-full  shadow-lg z-20"
          style={{ padding: '40px', maxHeight: '280px', overflowY: 'auto', borderRadius: '16px', backgroundColor:'#ededef' }} // Increased padding and added border-radius
        >
          <p className="text-black" style={{ fontSize: '12px' }}>{content}</p> {/* Font size set to 12px */}
        </div>
      ) : (
        // Display image slide when currentIndex > 0
        <div
          className="relative w-full h-full group"
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
          onClick={() => onImageClick(images[adjustedIndex]?.src, adjustedIndex)}
        >
          <Image
            src={images[adjustedIndex]?.src || ''}
            alt={`Slider Image ${adjustedIndex + 1}`}
            layout="fill"
            objectFit="cover"
            className="transition-all duration-500 ease-in-out cursor-pointer"
          />
        </div>
      )}

      {/* Arrow for Previous Slide */}
      <button
        className="absolute left-0 top-1/2 transform -translate-y-1/2 p-2 rounded-full z-30"
        onClick={prevSlide}
        style={{ backgroundColor: currentIndex === 0 ? 'black' : 'rgba(255, 255, 255, 0.25)' }} // Black if content is shown
      >
        <span className="transition text-20" style={{ color: 'white' }}>&#9664;</span>
      </button>
      
      {/* Arrow for Next Slide */}
      <button
        className="absolute right-0 top-1/2 transform -translate-y-1/2 p-2 rounded-full z-30"
        onClick={nextSlide}
        style={{ backgroundColor: currentIndex === 0 ? 'black' : 'rgba(255, 255, 255, 0.25)' }} // Black if content is shown
      >
        <span className="transition text-20" style={{ color: 'white' }}>&#9654;</span>
      </button>
    </div>
  );
}
