import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { CTAModule } from '../../components/enfusion/CTAModule';

function ACFCTAModule({ meta, data, className, anchor, pageProps }) {
    const { defaults } = pageProps;
    const {
        heading,
        cta,
        style_options_style_fluid_width,
        style_options_lightdark,
        style_options_background_image,
        use_block_default_data
    } = data;

    let defaultData = defaults?.ctaModule?.acf_cta_module;

    let background = meta?.images[style_options_background_image];
    
    return (
        <CTAModule
            className={className}
            anchor={anchor}
            heading={
                use_block_default_data !== '1' ? heading : defaultData?.heading
            }
            cta={use_block_default_data !== '1' ? cta : defaultData?.cta}
            fluid={
                use_block_default_data !== '1'
                    ? style_options_style_fluid_width
                    : defaultData?.styleOptions?.styleFluidWidth
            }
            background={
                use_block_default_data !== '1'
                    ? background
                    : defaultData?.styleOptions?.backgroundImage
            }
            light_dark={
                use_block_default_data !== '1'
                    ? style_options_lightdark
                    : defaultData?.styleOptions?.lightdark
            }
        />
    );
}

export default ACFCTAModule;
